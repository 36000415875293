import React from 'react'
import PageCustomers from '../components/pages/Customers/Customers'
import Layout from '../Layouts/zh-hk'

const customersZhHk = ({ location }) => {
  return (
    <Layout location={location}>
      <PageCustomers />
    </Layout>
  )
}

export default customersZhHk